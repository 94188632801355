<template>
    <div class="main-container">
        <div class="container-one">
            <div class="profile">
                <h1>Tomas Prieto</h1>
                <img src="../assets/profile_pic.png" alt="">
                <p>Software Developer</p>
                <p>(661) 619-2495</p>
                <p>prietotomas01@gmail.com</p>
            </div>
            <div class="social-links">

                <div class="link">
                    <a href="https://www.linkedin.com/in/tomasaprieto/" target="_blank">
                        <h3>LinkedIn</h3>
                    </a>
                </div>
                <div class="link">
                    <a href="https://github.com/TomasAPrieto/" target="_blank">
                        <h3>Github</h3>
                    </a>
                </div>
                <div class="link">
                    <a href="https://docs.google.com/document/d/1Ck6ghOQVQKWJj9O1883ztzhrBXqXkMo7zSUS4hsmeZ4/edit?usp=sharing"
                        target="_blank">
                        <h3>My Resume</h3>
                    </a>
                </div>

            </div>
        </div>
        <div class="container-two">
            <div class="about-me">
                <h2>About Me</h2>
                <p>Hi! I'm a newly graduated software engineer from Cal Poly SLO with a drive for programming and serving
                    others.
                    I'm especially interested in full-stack development, game design, and AI/ML.
                    Please feel free to reach out at any time!</p>
            </div>
            <div class="skills">
                <h2>Skills</h2>
                <p>Full-Stack Web / Mobile Development,
                    Game Design,
                    AWS, GCP, Azure,
                    Python, TypeScript, JavaScript, C++, Java, C, Kotlin, SQL,
                    React Native, React, Vue, Node, Angular, Flask, Bootstrap, jQuery,
                    DynamoDB, MongoDB, Firebase,
                    Unity, Android Studio, Linux/Unix,
                    Jira, Azure Devops, Git
                </p>
            </div>
            <!-- <div class="button-container">
                <div class="projects-button">
                    <a href="#ProjectsCard">Projects</a>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {

}
</script>
<style scoped>
img {
    height: 10rem;
    border-radius: 60%;
}

.main-container {
    display: flex;
    flex-direction: row;
    background-color: #99ffff;
    border-radius: 25px;
}

.container-one {
    background-color: #99ccff;
    padding: 2%;
    width: 30%;
    border-radius: 25px;
}

.container-two {
    background-color: #99ffff;
    padding: 2%;
    width: 70%;
    border-radius: 25px;
}

.link a {
    text-decoration: none;
    color: #2c3e50;
}

.about-me {
    text-align: left;
    margin: 2.5%;
}

.skills {
    text-align: left;
    margin: 2.5%;
}

.button-container {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.projects-button {
    background-color: #99ccff;
    margin: 5px;
    padding: 5px;
    border-radius: 15px;
}

.projects-button a{
    text-decoration: none;
    color: #2c3e50;
}
</style>